// Scripts for the division support block component
import { Swiper, Controller, A11y, Thumbs } from 'swiper/swiper.esm.js';

Swiper.use([Controller, A11y, Thumbs]);

// Get slides
let allSlides = document.querySelectorAll('.division-support__control-item');
console.log(allSlides.length);

// Controller slider
const supportControl = new Swiper(
  '.division-support__controller',
  {
    loop: false,
    preloadImages: false,
    spaceBetween: 0,
    slidesPerView: allSlides.length,
    slideToClickedSlide: true,
    touchRatio: 0,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
  }
);

// Main slider
const supportSlides = new Swiper(
  '.division-support__slider',
  {
    loop: true,
    preloadImages: false,
    thumbs: {
      swiper: supportControl,
    },
  }
);
